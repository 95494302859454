// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0
import axios from "axios"
import Cookies from "js-cookie"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { toast, Slide } from "react-toastify"
import Avatar from '@components/avatar'
import { Check, X } from 'react-feather'

dayjs.extend(timezone)
dayjs.extend(utc)

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const timeZoneToUTC = (time, timeZone, format = 'h:mm A MMMM D, YYYY') => {
  if (!timeZone) return dayjs(time).format(format)

  const offsetMin = dayjs().tz(timeZone).utcOffset()
  return dayjs(time).subtract(offsetMin, "m").format(format)
}

export const utcToTimeZone = (time, timeZone, format = 'h:mm A MMMM D, YYYY') => {
  if (!timeZone) return dayjs(time).format(format)
  const offsetMin = dayjs().tz(timeZone).utcOffset()
  return dayjs(time).add(offsetMin, "m").format(format)
}

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return 'N/A'

  const cleaned = phoneNumberString.replace(/\D/g, '')
  const match = cleaned.match(/^[\+1]?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return 'N/A'
}

export const telephoneCheck = (str) => {
  const patt = new RegExp(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)
  return patt.test(str)
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => !!Cookies.get("token")
export const getAccessToken = () => Cookies.get("token")

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getCookie = (key) => Cookies.get(key)

const renderHeader = (tokenSSR = null) => {
  let token = tokenSSR
  if (!token) {
    token = getCookie("token")
  }
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
}

export const swrConfig = {
  fetcher: (url) => axios.get(process.env.REACT_APP_ROOT_API + url, { headers: renderHeader() }).then((res) => res.data),
  onError: (error, key) => {
    // if (error.status == 401) {
    //   return Router.push('/');
    // }
  }
}

const ToastContent = ({ title, message }) => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size='sm' color='success' icon={<Check size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span>
        {message}
      </span>
    </div>
  </>
)

const ToastFail = ({ title, message }) => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size='sm' color='danger' icon={<X size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span>{message} </span>
    </div>
  </>
)

const toastComponent = (type, title, message) => {
  if (type === 'success') {
    return <ToastContent title={title} message={message} />
  }
  if (type === 'error') {
    return <ToastFail title={title} message={message} />
  }
}

export const showNotification = (type = 'success', title = 'Success', message) => {
  toast[type](toastComponent(type, title, message), {
    transition: Slide,
    hideProgressBar: true,
    autoClose: 2000
  })
}

export const ROOT_API = process.env.REACT_APP_ROOT_API || ""
export const MEDIA_PREFIX = process.env.REACT_APP_MEDIA_PREFIX || ""
